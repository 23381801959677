import moment from "moment";

// Function to calculate the time until the match starts
export const getTimeUntilMatchStart = (matchDate, matchTime) => {
  const matchDateTime = moment(`${matchDate} ${matchTime}`, "YYYY-MM-DD HH:mm");
  const now = moment();
  const duration = moment.duration(matchDateTime.diff(now));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  return { hours, minutes };
};

export const getTimeUntilMatchStartV2 = (CommenceTime) => {
  let now = moment(); // Get current UTC time
  let matchTime = moment(CommenceTime);

  let duration = moment.duration(matchTime.diff(now));
  let hours = Math.floor(duration.asHours());
  let minutes = Math.floor(duration.asMinutes()) % 60;
  return { hours, minutes };
};
