import React, { useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { isEligible } from "../../utils/isEligible";
import SoccerPrediction from "../soccer/SoccerPrediction";
import SoccerMatchDetails from "../soccer/SoccerMatchDetails";
import UpdatePlanModal from "../shared/UpdatePlanModal";
import SoccerPredictionEditModal from "../soccer/SoccerPredictionEditModal";
import moment from "moment";
import { getTimeUntilMatchStartV2 } from "../../utils/getTimeUntilMatchStart";
import NbaMatchDetails from "../shared/Nba/NbaMatchDetails";
import NbaEditModal from "../shared/Nba/NbaEditModal";
const NbaCard = ({ data }) => {
  const [predictionOpen, setPredictionOpen] = React.useState(false);
  const [predictionEditOpen, setPredictionEditOpen] = React.useState(false);
  const [matchDetailsOpen, setMatchDetailsOpen] = React.useState(false);
  const [matchDetailsData, setMatchDetailsData] = React.useState();
  const [predictionData, setPredictionData] = React.useState();
  const [notEligibleOpen, setNotEligibleOpen] = React.useState(false);
  const [startHours, setStartHours] = React.useState(null);
  const [startMinutes, setStartMinutes] = React.useState(null);
  const location = useLocation();
  const isAdminPath = location.pathname.split("/")[1] === "admin";
  const handlePrediction = (data) => {
    const eligible = isEligible(data);

    if (eligible) {
      setPredictionData(data?.fields);
      setPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  const handleEditPredictionModal = (data) => {
    setPredictionData(data);
    setPredictionEditOpen(true);
  };

  const handleMatchDetails = (data) => {
    setMatchDetailsData(data);
    setMatchDetailsOpen(true);
  };

  useEffect(() => {
    if (data) {
      const { hours, minutes } = getTimeUntilMatchStartV2(data?.CommenceTime);
      setStartHours(hours);
      setStartMinutes(minutes);
    }
  }, [data]);
  return (
    <>
      <div className="w-full bg-lightSecondary dark:bg-lightPrimary/10 text-secondary dark:text-lightSecondary min-h-[20vh] rounded-lg px-4 py-2">
        {/* league name and  */}
        <div className="flex justify-between items-center bg-primary/10 dark:bg-secondary/30 py-3 px-2 rounded-md">
          <div className="flex gap-2 items-center">
            <img src={"/logo-nba.svg"} alt="soccer" width={16} />
            <p className="text-xs font-semibold ">NBA</p>
          </div>
          <p className="text-xs font-bold bg-lightPrimary text-secondary px-2 rounded">
            Basketball
          </p>
        </div>

        {/* team name and logo section*/}
        <div className="mt-8 flex justify-between">
          <div className="flex gap-2 items-center">
            <img src={data?.HomeTeamLogo} alt="nba" className="w-6 h-6" />
            <p className="text-sm font-semibold">
              {data?.HomeTeam?.length > 15
                ? data?.HomeTeam.slice(0, 15) + "..."
                : data?.HomeTeam}
            </p>
          </div>
          <p className="font-semibold text-orange-600">VS</p>
          <div className="flex gap-2 items-center">
            <img src={data?.AwayTeamLogo} alt="soccer" className="w-6 h-6" />
            <p className="text-sm font-semibold">
              {data?.AwayTeam?.length > 15
                ? data?.AwayTeam.slice(0, 15) + "..."
                : data?.AwayTeam}
            </p>
          </div>
        </div>

        {/* status and time and date */}
        <div className="mt-4 flex justify-between">
          <div className="flex gap-2 items-center">
            {data?.status === "upcoming" ? (
              <FaRegStar className="text-black dark:text-white" />
            ) : (
              <FaStar
                className={`${
                  (data?.HomeScore > data?.AwayScore ? "Home" : "Away") !==
                  (data?.HomeWinPercentage > data?.AwayWinPercentage
                    ? "Home"
                    : "Away")
                    ? "text-red-600"
                    : "text-green-600"
                }`}
              />
            )}
            <p
              className={`text-sm font-semibold ${
                data?.status === "finished"
                  ? "text-orange-600"
                  : data.status === "upcoming"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {data?.status}
            </p>
          </div>
          <p className="text-sm">
            {moment(data?.CommenceTime).format("YYYY-MM-DD hh:mm A")}
          </p>
        </div>
        <div className="">
          <h3 className="text-md font-semibold text-green-700 text-center">
            Odds
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-4">
            <div className="flex flex-wrap justify-between border border-slate-200 bg-third/5 rounded-sm p-2">
              <p className="text-sm md:text-base font-semibold">
                {data?.HomeTeam}
              </p>
              <p className="text-xs md:text-sm font-semibold text-green-700">
                {data?.HomeTeamOdds === null ? "" : data?.HomeTeamOdds}
              </p>
            </div>

            <div className="flex flex-wrap justify-between border border-slate-200 bg-third/5 rounded-sm p-2">
              <p className="text-sm md:text-base font-semibold">
                {data?.AwayTeam}
              </p>
              <p className="text-xs md:text-sm font-semibold text-green-700">
                {data?.AwayTeamOdds === null ? "" : data?.AwayTeamOdds}
              </p>
            </div>
          </div>
        </div>

        {/* start in hours */}
        {(startHours || startMinutes) && (
          <div className="mt-8 flex justify-center items-center bg-primary/10 dark:bg-[#171b4e] py-2 rounded-md">
            {startHours > 0 || startMinutes > 0 ? (
              <p className="text-center text-sm font-semibold">
                {startHours} hrs {startMinutes} mins to
                start
              </p>
            ) : data?.status === "finished" ? (
              <p className="text-center text-sm font-semibold">
                Match has already finished
              </p>
            ) : (
              <p className="text-center text-sm font-semibold">
                Match has already started
              </p>
            )}
          </div>
        )}
        <div className="grid grid-cols-3 mt-4 mb-6 items-center">
          <h2 className="text-md font-semibold">We Predict : </h2>
          <div className="text-green-700 text-md font-semibold ">
            {data?.PredictedWinner}
          </div>
          <div className="flex flex-wrap justify-between border border-slate-200 bg-third/5 rounded p-2">
            <p className="text-sm font-semibold">Odds</p>
            <p className="text-sm font-semibold text-green-700">
              {data?.HomeWinPercentage > data?.AwayWinPercentage
                ? data?.HomeTeamOdds
                : data?.AwayTeamOdds}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4 mb-6 items-center align-middle">
          <h2 className="text-md  font-semibold ">Total PTS :</h2>
          <p className="text-md font-semibold text-green-700">
            {data?.totalPts}
          </p>
          <div className="flex flex-wrap justify-between border border-slate-200 bg-third/5 rounded p-2">
            <p className="text-sm font-semibold">Odds</p>
            <p className="text-sm md:text-sm font-semibold text-green-700">
              {data?.ptsOdds}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-4 mb-6 items-center">
          <h2 className="text-md font-semibold ">Handicap : </h2>
          <div className="text-green-700 text-sm md:text-md font-semibold "></div>
          <div className="flex flex-wrap justify-between border border-slate-200 bg-third/5 rounded p-2">
            <p className="text-sm font-semibold">Odds</p>
            <p className="text-sm font-semibold text-green-700">
              {data?.HomeWinPercentage > data?.AwayWinPercentage
                ? (data?.HomeTeamOdds * data?.ptsOdds).toFixed(2)
                : (data?.AwayTeamOdds * data?.ptsOdds).toFixed(2)}
            </p>
          </div>
        </div>
        {/* button section */}
        <div className="mt-6 flex justify-between gap-2">
          <button
            className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
            onClick={() => handleMatchDetails(data)}
          >
            MatCh Details
          </button>
          {isAdminPath && (
            <button
              className="w-full border border-secondary/50 hover:bg-secondary hover:text-lightSecondary dark:border-lightPrimary/20 dark:hover:bg-lightPrimary dark:hover:text-secondary text-xs font-semibold uppercase py-1.5 rounded-lg"
              onClick={() => handleEditPredictionModal(data)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      {/* <SoccerPrediction
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        data={predictionData}
      /> */}
      <NbaMatchDetails
        open={matchDetailsOpen}
        handleOpen={() => setMatchDetailsOpen(!matchDetailsOpen)}
        data={matchDetailsData}
      />

      {/* <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      /> */}
      <NbaEditModal
        open={predictionEditOpen}
        handleOpen={() => setPredictionEditOpen(!predictionEditOpen)}
        setPredictionEditOpen={setPredictionEditOpen}
        data={predictionData}
      />
    </>
  );
};

export default NbaCard;
