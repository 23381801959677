import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useAllNbaMatches = ({ date, status,uploadBr,limit,page }) => {
  const params = {};
  if (date) {
    params.date = date;
  }
  if (status) {
    if (status === "all") {
      delete params.status;
    } else {
      params.status = status;
    }
  }
  if (limit) {
    params.limit = limit;
  }
  if(page){
    params.page = page;
  }

  if(uploadBr){
    params.uploadBr = true;
  }
  return useQuery({
    queryKey: ["all-nba-matches", date, status,uploadBr,limit,page],
    queryFn: async () => {
      const response = await fetcher.get("/api/nba", { params });
      return response.data;
    },
    //   enabled: !!date,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
