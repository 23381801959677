/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
const CreateCategory = ({
  categoriesLoading,
  allCategories,
  setCategory,
  category,
  emptyFieldError,
  existingData,
}) => {
  const [previousCategory, setPreviousCategory] = useState(category);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputCategory, setInputCategory] = useState("");
  const inputHandler = (e) => {
    setInputCategory(e.target.value);
  };
  const handleRemoveSelectedCategory = (singleCategory) => {
    const newSelectedCategory = selectedCategory.filter(
      (item) => item !== singleCategory
    );
    setSelectedCategory(newSelectedCategory);
  };
  useEffect(() => {
    if (selectedCategory.length >= 0) {
      const selectedCategoryArray = [...selectedCategory, inputCategory];
      const inputCagtegoryArray = inputCategory.split(",");
      checkDuplicateCategories(selectedCategory, inputCagtegoryArray);
      const selectedCategoryString = Array.from(
        new Set(
          selectedCategoryArray
            .filter((item) => item.trim() !== "") // Remove empty strings
            .flatMap((item) => item.split(",")) // Split concatenated strings
            .map(
              (item) =>
                item
                  .trim()
                  .toLowerCase()
                  .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize first letter
            )
        )
      ).join(",");
      setCategory(selectedCategoryString);
    }
  }, [selectedCategory, inputCategory]);

  const checkDuplicateCategories = (selectedCategory, inputCategory) => {
    // Normalize the categories by trimming and converting to lowercase
    const normalizedSelectedCategory = selectedCategory.map((cat) =>
      cat.trim().toLowerCase()
    );
    const normalizedInputCategory = inputCategory.map((cat) =>
      cat.trim().toLowerCase()
    );
    // Check for duplicates
    const duplicateCategory = normalizedInputCategory.find((inputCat) =>
      normalizedSelectedCategory.includes(inputCat)
    );
    if (duplicateCategory) {
      alert(
        `The input category "${duplicateCategory}" has already been selected. Please select a new category.`
      );
    }
  };

  return (
    <>
      {categoriesLoading ? (
        <p className="text-gray-500 text-center w-full">Loading...</p>
      ) : (
        <div className="w-full flex flex-col justify-start items-start gap-2">
          <label htmlFor="category" className="font-medium">
            Category
          </label>

          {/* existing categories */}
          <div className="flex flex-wrap justify-start items-center gap-3">
            {allCategories?.map((singleCategory, idx) => (
              <div
                className={`flex items-center gap-2 font-medium capitalize px-1 py-0.5 rounded  ${
                  category?.includes(singleCategory) ||
                  previousCategory === singleCategory
                    ? "text-white bg-blue-500"
                    : "text-blue-500"
                }`}
                key={idx}
              >
                <button
                  className={` `}
                  onClick={() =>
                    setSelectedCategory([...selectedCategory, singleCategory])
                  }
                >
                  <div>{singleCategory}</div>
                </button>
                {selectedCategory.includes(singleCategory) && (
                  <div>
                    <RxCross1
                      onClick={() =>
                        handleRemoveSelectedCategory(singleCategory)
                      }
                      className="text-[12px] hover:text-gray-400"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          {allCategories?.data?.categories.length >= 1 && (
            <p className="w-full bg-gray-200 my-2 text-gray-800 font-medium text-center">
              or,
            </p>
          )}

          <input
            type="text"
            onChange={inputHandler}
            name="category"
            id="category"
            placeholder="Enter new category"
            className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full"
          />
          {/* empty field caution */}
          {emptyFieldError && !category && (
            <p className="text-red-600 bg-[#ff868636] font-semibold px-2 py-0.5 rounded text-sm">
              Please enter category
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default CreateCategory;
