import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import toast from "react-hot-toast";
import moment from "moment";
import { useUpdateNbaMatch } from "../../../hooks/useUpdateNbaMatch";
import { useUploadNbaMatch } from "../../../hooks/useUploadNbaMatch";
const NbaEditModal = ({ open, handleOpen, data, setPredictionEditOpen }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // Update form when `data` changes
  const {
    mutateAsync: updateMatch,
    isLoading,
    isSuccess,
    isError,
  } = useUpdateNbaMatch();

  const { mutateAsync: uploadMatch } = useUploadNbaMatch();
  useEffect(() => {
    if (data) {
      reset({
        HomeOdds: data?.HomeTeamOdds === null ? "" : data?.HomeTeamOdds,
        AwayOdds: data?.AwayTeamOdds === "nan" ? "" : data?.AwayTeamOdds,
        Prediction: data?.PredictedWinner || "",
        ptsOdds: data?.ptsOdds === null ? "" : data?.ptsOdds,
        // Date: data?.Date || "",
        // Time: data?.Time || "",
      });
    }
  }, [data, reset]);

  const onSubmit = async (formData) => {
    try {
      const response = await updateMatch({ id: data?.GameID, data: formData });
      if (response.success) {
        toast.success("Match updated successfully");
        setPredictionEditOpen(false);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const uploadMatchHandler = async () => {
    try {
      const response = await uploadMatch({
        id: data?.GameID,
        data: { uploadBr: true },
      });
      if (response.success) {
        toast.success("Match upload successfully");
        setPredictionEditOpen(false);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const cancelUploadMatchHandler = async () => {
    try {
      const response = await uploadMatch({
        id: data?.GameID,
        data: { uploadBr: false },
      });
      if (response.success) {
        toast.success("Cancel upload successfully");
        setPredictionEditOpen(false);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="p-6 bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        <div className="w-full flex justify-between items-center">
          <p>Our Prediction</p>
          <button
            className="bg-secondary/10 text-secondary dark:bg-lightPrimary/10 dark:text-lightPrimary p-2 w-12 h-12 rounded-full flex items-center justify-center"
            onClick={handleOpen}
          >
            <MdOutlineClose />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Top part */}
          <div className="flex justify-between items-center gap-4">
            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.HomeTeamLogo}
                alt={`${data?.HomeTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-secondary dark:text-lightPrimary">
                {data?.HomeTeam}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div>
                <p className="w-full text-xs text-center md:text-base text-secondary dark:text-lightPrimary bg-transparent">
                  {moment(data?.CommenceTime).format("YYYY-MM-DD")}
                </p>
              </div>
              <div>
                <p className="w-full text-center text-lg md:text-4xl font-semibold text-secondary dark:text-lightPrimary bg-transparent">
                  {moment(data?.CommenceTime).format("hh:mm A")}
                </p>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-2">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.AwayTeamLogo}
                alt={`${data?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-secondary dark:text-lightPrimary">
                {data?.AwayTeam}
              </p>
            </div>
          </div>

          <div className="w-full h-[1px] dark:bg-secondary/50 my-8"></div>

          {/* Odds Section */}
          <div>
            <h3 className="text-xl font-semibold text-green-700">Odds</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <div className="flex gap-2 border items-center justify-between p-2">
                  <p className="text-sm font-semibold text-secondary dark:text-lightPrimary">
                    {data?.HomeTeam}
                  </p>
                  <input
                    type="number"
                    step="any"
                    {...register("HomeOdds", {
                      required: "Home Odds is required",
                    })}
                    className="w-20 text-sm p-2 font-semibold text-secondary dark:text-lightPrimary bg-primary/5 dark:bg-secondary/20"
                  />
                </div>
                {errors.HomeOdds && (
                  <p className="text-red-500 text-xs mt-2 font-semibold">
                    {errors.HomeOdds.message}
                  </p>
                )}
              </div>

              <div>
                <div className=" flex gap-5 items-center border justify-between p-2">
                  <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
                    {data?.AwayTeam}:{" "}
                  </label>
                  <input
                    type="number"
                    step="any"
                    {...register("AwayOdds", {
                      required: "Away Odds is required",
                    })}
                    className="w-20 text-sm p-2 font-semibold text-secondary dark:text-lightPrimary bg-primary/5 dark:bg-secondary/20"
                  />
                </div>
                {errors.AwayOdds && (
                  <p className="text-red-500 text-xs font-semibold mt-2">
                    {errors.AwayOdds.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          {/* Prediction */}
          <div className="mt-6">
            <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
              Prediction:
            </label>
            <input
              type="text"
              {...register("Prediction", {
                required: "Prediction is required",
              })}
              className="w-full p-2 rounded-md bg-primary/5 dark:bg-secondary/20 text-secondary dark:text-lightPrimary"
            />
            {errors.Prediction && (
              <p className="text-red-500 text-xs mt-2 font-semibold">
                {errors.Prediction.message}
              </p>
            )}
          </div>
          <div className="mt-6">
            <label className="text-sm font-semibold text-secondary dark:text-lightPrimary">
              Total Pts Odds:
            </label>
            <input
              type="number"
              step="any"
              {...register("ptsOdds")}
              className="w-full p-2 rounded-md bg-primary/5 dark:bg-secondary/20 text-secondary dark:text-lightPrimary"
            />
            {errors.ptsOdds && (
              <p className="text-red-500 text-xs mt-2 font-semibold">
                {errors.ptsOdds.message}
              </p>
            )}
          </div>

          <div className="flex justify-end mt-6">
            {data?.uploadBr ? (
              <Button
                onClick={cancelUploadMatchHandler}
                className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-700 mr-4"
              >
                Cancel Upload
              </Button>
            ) : (
              <Button
                onClick={uploadMatchHandler}
                className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 mr-4"
              >
                Upload
              </Button>
            )}

            <Button
              type="submit"
              onLoad={isLoading}
              className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
            >
              Save
            </Button>
          </div>
        </form>
      </DialogBody>
    </Dialog>
  );
};

export default NbaEditModal;
